import {
  Text,
  Container,
  FormControl,
  FormHelperText,
  FormLabel,
  Input,
  InputGroup,
  InputRightElement,
  SimpleGrid,
  Spinner,
  useToast,
  Divider,
  Fade,
} from "@chakra-ui/react";
import { useDebounceCallback } from "@react-hook/debounce";
import { Link, navigate, PageProps } from "gatsby";
import React, { FC, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import Card from "src/atoms/Card";
import Layout from "src/components/Layout";

type SearchResult = {
  id: string;
  type: "search_result";
  attributes: {
    title: null;
    body_excerpt: string;
    highlight: {
      title: null;
      body: string[];
    };
    score: number;
    url: string;
  };
};

const GET_SLUG_REGEX = /\/articles\/(?<slug>.+)/;

const launchSearch = (input: string) => {
  return fetch(
    `https://site-api.datocms.com/search-results?q=${input}&build_trigger_id=${process.env.GATSBY_DATO_BUILD_TRIGGER_ID}`,
    {
      headers: {
        Authorization: `API-Token ${process.env.GATSBY_DATO_CMS_API_TOKEN}`,
        Accept: `application/json`,
      },
    }
  )
    .then((data) => data.json())
    .then((data) => data as { data: SearchResult[] });
};

const Search: FC<PageProps> = ({ location }) => {
  const searchParam = new URLSearchParams(location.search).get("search") ?? "";

  const [search, setSearch] = useState(searchParam);
  const [articles, setArticles] = useState<SearchResult[] | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  const toast = useToast();

  const setParam = useDebounceCallback(
    (input: string) => navigate(input ? `?search=${input}` : "?"),
    500,
    true
  );

  useEffect(() => {
    if (searchParam.length === 0) setArticles(null);
    if (searchParam.length < 4) return;

    setIsLoading(true);

    launchSearch(search)
      .catch(() => {
        toast({
          title: "Une erreur est survenue",
          description: "La recherche n'a pas pu aboutir.",
          status: "warning",
        });

        throw Error();
      })
      .then((results) => {
        setArticles(
          results.data?.filter((article) =>
            article.attributes.url.includes("/articles/")
          )
        );
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [searchParam]);

  return (
    <Layout>
      <Helmet
        meta={[
          {
            name: "description",
            content:
              "Effectuer une recherche dans les articles de la Société Francophone de Philosophie de la Religion",
          },
        ]}
        title="Recherche"
      />

      <Container maxW="container.xl">
        <Card size="lg">
          <FormControl
            align="center"
            display="flex"
            flexDirection="column"
            mt={4}
          >
            <FormLabel htmlFor="email" textAlign="center">
              Entrez votre recherche
            </FormLabel>

            <InputGroup
              alignItems="center"
              id="search"
              maxW="350px"
              mt={4}
              mx="auto"
            >
              <Input
                onChange={(event) => {
                  setSearch(event.target.value);
                  setParam(event.target.value);
                }}
                size="lg"
                value={search}
              />

              <InputRightElement height="full" pr={4}>
                {isLoading && (
                  <Spinner color="gray.400" size="sm" speed=".75s" />
                )}
              </InputRightElement>
            </InputGroup>

            <FormHelperText minHeight="17px">
              <Fade in={search.length < 4}>
                Commencez à taper quelque chose...
              </Fade>
            </FormHelperText>

            <Fade in={searchParam !== "" && articles?.length === 0}>
              <Text mt={8}>Aucun résultat pour cette recherche.</Text>
            </Fade>
          </FormControl>
        </Card>

        <SimpleGrid columns={3} mt={8} spacing={4}>
          {articles?.slice(0, 12).map((article) => (
            <Card
              as={Link}
              key={article.id}
              to={`/articles/${
                article.attributes.url.match(GET_SLUG_REGEX)?.groups?.slug
              }`}
            >
              <Text fontWeight="semibold">
                {article.attributes.body_excerpt
                  .replace(/[^|]+ \|/, "")

                  .concat("...")}
              </Text>

              <Divider mb={5} mt={3} />

              <Text fontSize="sm">
                {article.attributes.highlight.body.map((highlight) => {
                  const asString = highlight
                    .replace("[h]", "<strong>")
                    .replace("[/h]", "</strong>")
                    .concat("<em> [...] </em>");

                  return (
                    <Text
                      as="cite"
                      dangerouslySetInnerHTML={{ __html: asString }}
                      key={asString}
                      sx={{
                        strong: {
                          bg: "yellow",
                        },
                        em: {
                          color: "gray.400",
                        },
                      }}
                    />
                  );
                })}
              </Text>
            </Card>
          ))}
        </SimpleGrid>
      </Container>
    </Layout>
  );
};

export default Search;
